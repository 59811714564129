#stripeEl * {
	 box-sizing: border-box;
}
 #stripeEl input, #stripeEl button {
	 -webkit-appearance: none;
	 -moz-appearance: none;
	 appearance: none;
	 outline: none;
	 border-style: none;
}
 #stripeEl .AppWrapper {
	 width: 500px;
	 height: 400px;
	 position: relative;
}
 @keyframes fade {
	 from {
		 opacity: 0;
		 transform: scale3D(0.95, 0.95, 0.95);
	}
	 to {
		 opacity: 1;
		 transform: scale3D(1, 1, 1);
	}
}
 #stripeEl .Form {
	 animation: fade 200ms ease-out;
}
 #stripeEl .FormGroup {
	 margin: 0 15px 20px;
	 padding: 0;
	 border-style: none;
	 background-color: transparent;
	 will-change: opacity, transform;
	 border-radius: 4px;
}
 #stripeEl .FormRow {
	 display: -ms-flexbox;
	 display: flex;
	 -ms-flex-align: center;
	 align-items: center;
	 margin-left: 15px;
	 border: 1px solid #bababa;
}
 #stripeEl .FormRow:nth-child(2) {
	 border-top: none;
}
 #stripeEl .FormRowLabel {
	 width: 15%;
	 min-width: 70px;
	 padding: 11px;
	 color: #262626;
	 overflow: hidden;
	 text-overflow: ellipsis;
	 white-space: nowrap;
}
 @keyframes void-animation-out {
	 0%, to {
		 opacity: 1;
	}
}
 #stripeEl .FormRowInput:-webkit-autofill {
	 -webkit-text-fill-color: #000;
	/* Hack to hide the default webkit autofill */
	 transition: background-color 100000000s;
	 animation: 1ms void-animation-out;
}
 #stripeEl .FormRowInput {
	 font-size: 16px;
	 width: 100%;
	 padding: 11px 15px 11px 0;
	 color: #262626;
	 background-color: transparent;
	 animation: 1ms void-animation-out;
}
 #stripeEl input.InputElement::placeholder, #stripeEl input.FormRowInput::placeholder {
	 color: #454545 !important;
}
 #stripeEl .ElementsApp .Icon-fill {
	 color: #454545 !important;
}
 #stripeEl .StripeElement--webkit-autofill {
	 background: transparent !important;
}
 #stripeEl .StripeElement {
	 width: 100%;
	 padding: 11px 15px 11px 0;
}
 #stripeEl .SubmitButton {
	 display: block;
	 font-size: 16px;
	 width: calc(100% - 30px);
	 height: 47px;
	 margin: 40px 15px 0;
	 background-color: #5000a0;
	 box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ccc;
	 border-radius: 4px;
	 color: #fff;
	 font-weight: 600;
	 cursor: pointer;
	 transition: all 100ms ease-in-out;
	 will-change: transform, background-color, box-shadow;
}
 #stripeEl .SubmitButton:active {
	 background-color: #5000a0;
	 box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #5000a0;
	 transform: scale(0.99);
}
 #stripeEl .SubmitButton.SubmitButton--error {
	 transform: translateY(15px);
	 background-color: #ccc;
}
 #stripeEl .SubmitButton.SubmitButton--error:active {
	 transform: scale(0.99) translateY(15px);
}
 #stripeEl .SubmitButton:disabled {
	 opacity: 0.5;
	 cursor: default;
	 background-color: #7795f8;
	 box-shadow: none;
}
 #stripeEl .ErrorMessage {
	 color: #5000a0;
	 position: absolute;
	 display: flex;
	 justify-content: center;
	 padding: 0 15px;
	 font-size: 13px;
	 margin-top: 0px;
	 width: 100%;
	 transform: translateY(-15px);
	 opacity: 0;
	 animation: fade 150ms ease-out;
	 animation-delay: 50ms;
	 animation-fill-mode: forwards;
	 will-change: opacity, transform;
}
 #stripeEl .ErrorMessage svg {
	 margin-right: 10px;
}
 #stripeEl .Result {
	 margin-top: 50px;
	 text-align: center;
	 animation: fade 200ms ease-out;
}
 #stripeEl .ResultTitle {
	 color: #fff;
	 font-weight: 500;
	 margin-bottom: 8px;
	 font-size: 17px;
	 text-align: center;
}
 #stripeEl .ResultMessage {
	 color: #5000a0;
	 font-size: 14px;
	 font-weight: 400;
	 margin-bottom: 25px;
	 line-height: 1.6em;
	 text-align: center;
}
 #stripeEl .ResetButton {
	 border: 0;
	 cursor: pointer;
	 background: transparent;
}
 